$primary: #8a2027;
$secondary: #373790;
$sRed: #ff4747;
$sGreen: #71c02b;
$primaryNormal: #914247;
$white: #ffffff;
$background: #f5f7ff;
$primaryLight: #b79396;
$black: rgb(24, 24, 24);
$lightBlack: rgb(58, 58, 58);
